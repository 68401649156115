import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { userToolStore, reposicaoStore, analyticsStore } from '@/store';
import GenericListItemsCardComponent from '@/components/GenericListItemsCardComponent.vue';
import GenericListTotalCardComponent from '@/components/GenericListTotalCardComponent.vue';
import _ from 'lodash';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
let ReposicaoList = class ReposicaoList extends Vue {
    constructor() {
        super(...arguments);
        this.loading = true;
        this.search = '';
        this.selectPeriodDate = null;
        this.selectedItem = {};
        this.tab = 0;
        this.headers = [
            {
                text: 'Horário',
                table: 'reposicao',
                value: 'data_hora',
                show: true,
            },
            {
                text: 'Sala',
                value: 'sala_nome',
                table: 'sala',
                field: 'nome',
                type: 'str',
                show: true,
            },
            {
                text: 'Alunos',
                value: 'alunos',
                table: 'aluno',
                type: 'str',
                show: true,
            },
            {
                text: 'Professor',
                value: 'professor_full_name',
                table: 'professor',
                field: 'full_name',
                type: 'str',
                show: true,
            },
            {
                text: 'Livro',
                value: 'livro_nome',
                table: 'atividade',
                field: 'livro_nome',
                type: 'str',
                show: true,
            },
            {
                text: 'Atividade',
                value: 'descricao',
                table: 'atividade',
                field: 'descricao',
                type: 'str',
                show: true,
            },
            {
                text: 'Status',
                value: 'status',
                table: 'reposicao',
                show: true,
            },
            {
                text: 'Ações',
                value: 'actions',
                show: true,
            },
        ];
    }
    get allowView() {
        if (userToolStore.userToolsIndexed[`Controle reposição`]) {
            return userToolStore.userToolsIndexed[`Controle reposição`][0].allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed[`Controle reposição`]) {
            return userToolStore.userToolsIndexed[`Controle reposição`][0].allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed[`Controle reposição`]) {
            return userToolStore.userToolsIndexed[`Controle reposição`][0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed[`Controle reposição`]) {
            return userToolStore.userToolsIndexed[`Controle reposição`][0].allow_delete;
        }
    }
    get totals() {
        return analyticsStore.totals;
    }
    get reposicoes() {
        return reposicaoStore.reposicoes;
    }
    reposicaoStatusOptions(id) {
        switch (id) {
            case 0:
                return 'Aula não lecionada';
            case 1:
                return 'Aula lecionada';
        }
    }
    filter(value, search, item) {
        const inDescription = RegExp(search, 'i').test(value);
        // --------------------------------------------------
        const aluno_nomes = _.map(item.alunos, 'aluno_nome');
        const r = aluno_nomes.some((i) => {
            return RegExp(search, 'i').test(i);
        });
        // --------------------------------------------------
        return inDescription || r;
    }
    async deleteItem(id) {
        this.$swal({
            title: 'Confirma a exclusão da reposição?',
            text: 'Após exclusão a reposição não pode ser recuperada!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.loading = true;
                // @ts-ignore
                await reposicaoStore.deleteReposicao(id);
                // @ts-ignore
                await this.buscar();
                this.loading = false;
            }
        });
    }
    async buscar() {
        analyticsStore.setDefaultFilters({
            'reposicao.data_hora': {
                type: 'timestamp',
                value: [this.selectPeriodDate[0], this.selectPeriodDate[1]],
            },
        });
        this.doSearchTotals();
        await analyticsStore.executeSearchItems();
    }
    async doSearchTotals() {
        await analyticsStore.getAnalyticsReposicao({
            data_inicial: this.selectPeriodDate[0],
            data_final: this.selectPeriodDate[1],
        });
    }
    async mounted() {
        analyticsStore.initAnalytics();
        analyticsStore.setFnSearchItems(reposicaoStore.getReposicoesReposicaoQuery);
        analyticsStore.setHeaders(this.headers);
    }
    routeEdit(id, id_turma) {
        // @ts-ignore
        this.$router.push({
            name: 'main-reposicoes-edit',
            params: { id, id_turma },
        });
    }
};
ReposicaoList = __decorate([
    Component({
        components: {
            PeriodSelectorComponent,
            GenericListItemsCardComponent,
            GenericListTotalCardComponent,
        },
    })
], ReposicaoList);
export default ReposicaoList;
