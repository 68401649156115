var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Controle reposição")])]),_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('period-selector-component',{attrs:{"prop-title":"Data da falta"},model:{value:(_vm.selectPeriodDate),callback:function ($$v) {_vm.selectPeriodDate=$$v},expression:"selectPeriodDate"}})],1),_c('v-col',[_c('v-btn',{staticClass:"primary",on:{"click":function($event){return _vm.buscar()}}},[_vm._v(" buscar")])],1)],1),_c('v-row',[(_vm.totals.por_status)?_c('v-col',[_c('GenericListTotalCardComponent',{key:"por_status",attrs:{"prop-title":"Por status","prop-items":_vm.totals.por_status}})],1):_vm._e()],1),_c('v-row',[_c('v-col',[_c('GenericListItemsCardComponent',{attrs:{"propTableName":"list_reposicoes"},scopedSlots:_vm._u([{key:"item.data_hora",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.data_hora))+" ")]}},{key:"item.alunos",fn:function(ref){
var value = ref.value;
return [(value)?_c('div',_vm._l((value),function(aluno,idx){return _c('div',{key:idx},[_c('v-chip',{staticClass:"ma-1",attrs:{"small":"","label":""}},[_vm._v(" "+_vm._s(aluno)+" ")])],1)}),0):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [[_c('span',[_vm._v(" "+_vm._s(_vm.reposicaoStatusOptions(item.status))+" ")])]]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.allowEdit)?_c('v-icon',{on:{"click":function($event){return _vm.routeEdit(item.id, item.turma_aluno_atividade_id)}}},[_vm._v("mdi-eye")]):_vm._e(),(_vm.allowDelete)?_c('v-icon',{on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_vm._v("mdi-delete")]):_vm._e()]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }